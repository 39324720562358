<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form action="#" @submit.prevent="submit">
          <div class="form-body">
            <h3 class="card-title">Retensi Folder</h3>
            <hr>

            <div class="row p-t-20">
              
              <!-- <div class="col-md-4">
                <label :class="['control-label']">
                  Id Folder
                  <small style="color:red">*</small>
                </label>
                <div>
                  <Input v-model="payload.idfolder" name="idfolder" required type="text" />
                </div>
              </div> -->
              
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                    Retensi Inactive
                  </label>
                  <div class="col-md-8">
                    <select v-model="payload.retensi_inaktif" :class="['form-control', {'text-right': !isMobile}, 'col-md-4']">
                       <option disabled value="">Please select one</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>13</option>
                      <option>14</option>
                      <option>15</option>
                      <option>16</option>
                      <option>17</option>
                      <option>18</option>
                      <option>19</option>
                      <option>20</option>
                    </select>
                    <label :class="['control-label', {'text-left': !isMobile}, 'col-md-4']">
                    Tahun
                  </label>
                  </div>
                </div>
              </div>
            </div>

            <hr class="m-t-40">

          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-success" title="Simpan">
              <i class="fa fa-check"></i> Save
            </button> &nbsp;
            <button type="button" @click="goBack" class="btn btn-inverse" title="Cancel">Cancel</button>
          </div>
        </form>
      </div>
      <!-- {{ this.state.detail.tanggal_created }} -->
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
import store from "@/store";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.folderdinilai;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable"
      ],
      payload: {
        // idfolder: this.state.detail.idfolder,
        retensi_inaktif: "",
      },
      myHTML: "",
      isDisabled: false
    };
  },
  async mounted() {
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/folderdinilai/${this.$route.params.id}`).then(response => {
            const state = {
              loaded: true
            };
            this.$store.commit("folderdinilai/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("folderdinilai/onAdd");
          // axios.get(`/document_in/new`).then(response => {
          //   // this.payload.agenda = response.data.data.agenda_number;
          //   // this.payload.tanggal = response.data.data.document_date;
          // });
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        id_typesurat: data.document_typesurat,
        asal: data.document_asalsurat,
      };
      this.myHTML = data.description;
    },
    goBack() {
      this.$store.dispatch("folderdinilai/onCancel");
    },
    submit() {
      // const data = {
      //   idfolder: this.state.detail.idfolder,
      //   retensi_inaktif: this.payload.retensi_inaktif,
      // };
      const payload = {
        // idfolder: this.state.detail.idfolder,
        idfolder: this.$route.params.id,
        tgl_created: this.state.detail.tanggal_created,
        retensi_inaktif: parseInt(this.payload.retensi_inaktif),
        flag_folder: 1,
      };
      const data = payload;
      this.$store.dispatch("folderdinilai/submitRetensi", data);
    },
    // submit() {
      
    //   const payload = {
    //     idfolder: this.state.detail.idfolder,
    //     retensi_aktif: this.payload.retensi_aktif,
    //   };
    //   const data = JSON.stringify(payload);
    //   this.$validator.validateAll().then(success => {
    //     if (
    //       success &&
    //       !this.isInvalidTypeSurat
    //     ) {
    //       if (this.editMode) {
    //         this.$store.dispatch("folderdinilai/submitRetensi", {
    //           data,
    //           id: this.$route.params.id
    //         });
    //       } else {
    //         this.$store.dispatch("folderdinilai/submitRetensi", data);
    //       }
    //     }
    //   });
    // },
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

